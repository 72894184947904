<template>
    <div class="card">
        <div class="card-header row">
          <div class="col-md-8"> 
            <div class="d-flex align-items-center">
              <h6 class="card-title font-weight-semibold">Informasi SEP</h6>
            </div>
          </div>
        </div>

        <table class="table table-bordered table-sm text-uppercase">
            <tbody>
                <tr>
                    <td colspan="3">
                    <div class="result_tab">
                        <h4>Data Pasien</h4>
                    </div>
                    </td>
                </tr>
                <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>No. Rekam Medis</h4>
                        <p>{{row.ap_code||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Nama Pasien</h4>
                        <p>{{row.ap_fullname||"-"}}</p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Tempat, Tanggal Lahir </h4>
                        <p>{{row.ap_pob||"-"}}, {{row.ap_dob | moment("DD MMMM YYYY")}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Usia</h4>
                        <p>{{row.ap_usia_with_ket||"-"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Jenis Kelamin</h4>
                        <p>{{row.cg_label||"-"}}</p>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colspan="3">
                    <div class="result_tab">
                        <h4>Data SEP</h4>
                    </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="result_tab">
                        <h4>No SEP</h4>
                        <p>{{row.absd_no_sep||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Tanggal SEP </h4>
                        <p>{{row.absd_tgl_sep | moment("DD MMMM YYYY")}}</p>
                        </div>
                    </td>
                    <td v-if="row.absd_type_sep != 'I'">
                        <div class="result_tab">
                        <h4>Tanggal Rujuk</h4>
                        <p>{{row.absd_tgl_rujuk | moment("DD MMMM YYYY")}}</p>
                        </div>
                    </td>
                </tr>
                <tr v-if="row.absd_type_sep != 'I'">
                    <td>
                        <div class="result_tab">
                        <h4>NO. RUJUKAN</h4>
                        <p>{{row.absd_no_rujuk||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>NAMA PPK RUJUKAN </h4>
                        <p>{{row.absd_ppk_rujukan||"-"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Nama PPK Pelayanan</h4>
                        <p>{{row.absd_ppk_pelayanan||"-"}}</p>
                        </div>
                    </td>
                </tr>
                
                <tr>
                    <td>
                        <div class="result_tab">
                        <h4>JENIS</h4>
                        <p>
                            <span>{{getConfigDynamic(Config.mr.jenisPelayanan,row.absd_jenis)|| "-"}}</span>
                        </p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Diagnosa</h4>
                        <p>{{row.absd_diagnosa_pelayanan||"-"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Catatan</h4>
                        <p>{{row.absd_catatan||"-"}}</p>
                        </div>
                    </td>
                </tr>
                                
                <tr>
                    <td>
                        <div class="result_tab">
                        <h4>Kode Pelayanan</h4>
                        <p>{{row.absd_kd_pelayanan||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Kelas Rawat</h4>
                        <p>{{getConfigDynamic(Config.mr.kelasRawat,row.absd_kelas_rawat)||"-"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Naik Kelas</h4>
                        <p>{{row.absd_naik_kelas||"-"}}</p>
                        </div>
                    </td>
                </tr>
                
                <tr>
                    <td>
                        <div class="result_tab">
                        <h4>Pembiayaan</h4>
                        <p>{{row.absd_pembiayaan||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Laka lantas</h4>
                        <p>{{getConfigDynamic(Config.mr.configLakaLantas,row.absd_laka_lantas)||"Tidak"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>User Input</h4>
                        <p>{{row.absd_user_input||"-"}}</p>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div class="result_tab">
                        <h4>Peserta</h4>
                        <p>{{row.absd_peserta||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Gender</h4>
                        <p>{{(row.ap_gender == 1 ? 'Laki-Laki':'Perempuan')||"-"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Nomor Kartu</h4>
                        <p>{{row.absd_no_kartu||"-"}}</p>
                        </div>
                    </td>
                </tr>

                <tr v-if="row.absd_type_sep != 'I'">
                    <td>
                        <div class="result_tab">
                        <h4>Tanggal Pulang</h4>
                        <p>{{row.absd_tanggal_pulang | moment("DD MMMM YYYY")}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Assal Rujukan</h4>
                        <p>{{getConfigDynamic(Config.mr.jenisFaskes,row.absd_asal_rujukan)||"-"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>No LP</h4>
                        <p>{{row.absd_no_lp||"-"}}</p>
                        </div>
                    </td>
                </tr>
                
                <tr>
                    <td>
                        <div class="result_tab">
                        <h4>Eksekutif</h4>
                        <p>{{getConfigDynamic(Config.mr.yesNoOptV3,row.absd_eksekutif)||"Tidak"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>COB</h4>
                        <p>{{getConfigDynamic(Config.mr.yesNoOptV3,row.absd_cob)||"Tidak"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Phone Number</h4>
                        <p>{{getConfigDynamic(Config.mr.yesNoOptV3,row.ap_phone_number)||"-"}}</p>
                        </div>
                    </td>
                </tr>
                
                
                <tr>
                    <td>
                        <div class="result_tab">
                        <h4>Katarak</h4>
                        <p>{{getConfigDynamic(Config.mr.yesNoOptV3,row.absd_katarak)||"Tidak"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Tanggal KLL</h4>
                        <p>{{row.absd_tanggal_kll | moment("DD MMMM YYYY")}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Keterangan KLL</h4>
                        <p>{{row.absd_keterangan_kll||"-"}}</p>
                        </div>
                    </td>
                </tr>
                
                
                <tr>
                    <td>
                        <div class="result_tab">
                        <h4>Suplesi</h4>
                        <p>{{getConfigDynamic(Config.mr.yesNoOptV3,row.absd_is_kll)||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>No Suplesi</h4>
                        <p>{{row.absd_no_sep_suplesi||"-"}}</p>
                        </div>
                    </td>
                    <td v-if="row.absd_type_sep != 'I'">
                        <div class="result_tab">
                        <h4>No SKDP</h4>
                        <p>{{row.absd_no_skdp||"-"}}</p>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div class="result_tab">
                        <h4>Provinsi</h4>
                        <p>{{row.absd_provinsi_text||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Kabupaten</h4>
                        <p>{{row.absd_kabupaten_text||"-"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Kecamatan</h4>
                        <p>{{row.absd_kecamatan_text||"-"}}</p>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>DPJP / DPJP Layanan</h4>
                        <p>{{row.absd_dpjp||"-"}} {{row.absd_dpjp_name||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>DPJP / DPJP Pemberi SKDP</h4>
                        <p>{{row.absd_dpjp_skdp||"-"}} {{row.absd_dpjp_skdp_name||"-"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Tujuan Kunjungan</h4>
                        <p>{{getConfigDynamic(Config.mr.configTujuan,row.absd_tujuan_kunjungan)||"-"}}</p>
                        </div>
                    </td>
                </tr>

                <tr>
                    <td>
                        <div class="result_tab">
                        <h4>Flag</h4>
                        <p>{{getConfigDynamic(Config.mr.configFlag,row.absd_flag)||"-"}}</p>
                        </div>
                    </td>
                    <td width="33%">
                        <div class="result_tab">
                        <h4>Penunjang</h4>
                        <p>{{getConfigDynamic(Config.mr.configPenunjang,row.absd_penunjang)||"-"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                        <h4>Assesmen Layanan</h4>
                        <p>{{row.absd_assesmen_layanan||"-"}}</p>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>


<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'


export default{
  props:{
    row:Object,
    mrValidation:Object,
    mPasien: Array,
    mKecamatan : Array,
    mKabupaten : Array,
    mProvinsi : Array,
    mPoli : Array,
    Config:Object
  },
  methods: {
    getConfigDynamic(master, value) {
        let text = ''
        if (value) {
            let index = (master||[]).findIndex(x => x.value == value)
            if (index !== -1) {
            text = master[index]['text']
            }
        }
        return text
    },
  },
}
  
</script>